import React from 'react';
import { VideoDetail } from "components"
import { graphql } from 'gatsby';


export default function VideoDetailTemplate(props) {
  return <VideoDetail {...props} />
}

export const pageQuery = graphql`
  query VideoByKeyMediaId($key: String!) {
    sitePage(context: {key: {eq: $key}}) {
      context {
        description
        key
        tags
        title
        smallFeaturedImage
      }
    }
  }
`;
